'use client';

import { clsx } from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import { ComponentProps, FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { addWatchlistItem, removeWatchlistItem } from 'src/data/WatchListApi/WatchlistApi';
import { IconHeart } from 'src/general/Icons/IconHeart';
import { IconHeartFilled } from 'src/general/Icons/IconHeartFilled';
import { watchlistStore } from 'src/stores/watchListStore';
import { ProductListDataRow } from 'src/types/CataloguePage.types';
import {
  DataLayerWatchlistEvent,
  generateSourceString,
  pushToDataLayerWatchList,
} from 'src/utils/pushToDataLayerWatchList';
import { authStore } from '../../../stores/authStore';
import { Button } from '../Button/Button';
import { ButtonIcon } from '../Button/ButtonIcon';
import { ToasterService } from '../Toaster/ToasterService';
import styles from './AddToWatchList.module.scss';

export interface WatchListItem {
  sku: string;
  product?: Partial<ProductListDataRow>;
  createdAt: string;
}

type LabelsOverwrite = {
  save: ReactNode;
  saved: ReactNode;
};

type AddToWatchListProps = {
  sku: string;
  product?: Partial<ProductListDataRow>;
  className?: string;
  labelsOverwrite?: LabelsOverwrite;
  disableSnackbar?: boolean;
  onWatchlistStatusChange?: () => Promise<void>;
} & (
  | { variant?: 'icon' }
  | {
      variant: 'button';
      buttonProps?: Pick<ComponentProps<typeof Button>, 'variant' | 'fullWidth' | 'size'>;
    }
);

const addToWatchlistMessage = 'Vehicle has been added to your watchlist.';
const addToWatchlistTooManyErrorMessage =
  "You've added 250 cars to your watchlist. To add more, please remove another first.";
const addToWatchlistGenericErrorMessage = 'Failed to add vehicle to your watchlist.';
const removeFromWatchlistMessage = 'Vehicle has been removed from your watchlist.';

export const AddToWatchList: FC<AddToWatchListProps> = ({
  sku,
  product = {},
  className,
  labelsOverwrite,
  onWatchlistStatusChange,
  disableSnackbar = false,
  ...props
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const { isUserLoggedIn } = authStore(
    (state) => ({
      isUserLoggedIn: state.isUserLoggedIn,
    }),
    shallow,
  );
  const { hasWatchlistItem, setWatchlistData } = watchlistStore(
    (state) => ({
      hasWatchlistItem: state.watchlist.has(sku),
      setWatchlistData: state.setWatchlistData,
    }),
    shallow,
  );
  const [optimisticallyAdded, setOptimisticallyAdded] = useState<boolean | undefined>(undefined);

  const userAddedItem = useMemo(
    () => optimisticallyAdded ?? hasWatchlistItem,
    [optimisticallyAdded, sku, hasWatchlistItem],
  );

  const handleAddWatchlistItem = useCallback(() => {
    setOptimisticallyAdded(true);
    addWatchlistItem({ sku })
      .then((response) => {
        setWatchlistData(response ?? null);
        if (!disableSnackbar) {
          ToasterService.success({ message: addToWatchlistMessage });
        }
        if (onWatchlistStatusChange) {
          onWatchlistStatusChange();
        }
      })
      .catch((response) => {
        setOptimisticallyAdded(false);
        if (disableSnackbar) {
          return;
        }

        if (response.response.status === 409) {
          ToasterService.error({ message: addToWatchlistTooManyErrorMessage });
          return;
        }
        ToasterService.error(
          { message: addToWatchlistGenericErrorMessage },
          {
            autoClose: 5000,
          },
        );
      });
  }, [sku]);

  const onUndo = useCallback(() => {
    handleAddWatchlistItem();
    pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_undos, { sku });
  }, [handleAddWatchlistItem]);

  const handleRemoveWatchlistItem = useCallback(() => {
    setOptimisticallyAdded(false);
    if (!disableSnackbar) {
      ToasterService.info({ message: removeFromWatchlistMessage, action: { type: 'undo', onAction: onUndo } });
    }
    removeWatchlistItem(sku).then((response) => {
      setWatchlistData(response ?? null);
      if (onWatchlistStatusChange) {
        onWatchlistStatusChange();
      }
    });
  }, [sku, handleAddWatchlistItem]);

  const handleClick = useCallback(() => {
    if (!isUserLoggedIn) {
      pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_add_attempt, { sku });
      const redirectUrl = new URL(window.location.toString());
      redirectUrl.searchParams.append('watchlistSku', sku);
      const queryParams = new URLSearchParams({
        type: 'watchlist',
        sku: sku,
        source: `${generateSourceString(pathname ?? '')}`,
        redirect: redirectUrl.toString(),
      });

      router.push(`/signin?${queryParams.toString()}`);
    } else if (userAddedItem) {
      handleRemoveWatchlistItem();
      pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_remove, { sku });
    } else {
      handleAddWatchlistItem();
      pushToDataLayerWatchList(DataLayerWatchlistEvent.watchlist_successful_add, { sku, product });
    }
  }, [isUserLoggedIn, userAddedItem, handleAddWatchlistItem, handleRemoveWatchlistItem]);

  const buttonLabel = getButtonLabel(userAddedItem, labelsOverwrite);

  if (props.variant === 'button') {
    return (
      <Button
        size="small"
        {...props.buttonProps}
        onClick={handleClick}
        data-testid={`AddToWatchList-component-button-${userAddedItem ? 'Saved' : 'Save'}`}
        startIcon={
          userAddedItem ? (
            <IconHeartFilled className="watchlistButton-icon" />
          ) : (
            <IconHeart className="watchlistButton-icon" />
          )
        }
        className={clsx('watchlistButton', className, {
          [styles.watchlistfilled]: userAddedItem,
          watchlistfilled: userAddedItem,
        })}
      >
        {buttonLabel}
      </Button>
    );
  }

  return (
    <ButtonIcon
      type="button"
      size="small"
      variant="tertiary"
      styling="round"
      className={clsx(styles.watchlist, 'watchlistButton', className, styles.watchlistIcon, {
        [styles.watchlistfilled]: userAddedItem,
        watchlistfilled: userAddedItem,
      })}
      onClick={handleClick}
      data-testid={`AddToWatchList-component-button-${userAddedItem ? 'Saved' : 'Save'}`}
      title="Add to watchlist"
      Icon={userAddedItem ? IconHeartFilled : IconHeart}
    />
  );
};

const getButtonLabel = (userAddedItem: boolean, labelsOverwrite?: LabelsOverwrite) => {
  if (userAddedItem) {
    return labelsOverwrite ? labelsOverwrite.saved : 'Saved';
  }

  return labelsOverwrite ? labelsOverwrite.save : 'Save';
};
