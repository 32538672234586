import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

import { Image } from '../Image/Image';

import styles from './PromoPill.module.scss';

interface PromoPillProps {
  promoPillData: {
    key: string | number;
    copy: ReactNode;
    icon?: string;
    backgroundColor?: string;
    color?: string;
  }[];
  className?: string;
}
export const PromoPill: FC<PromoPillProps> = (props: PromoPillProps) => {
  const { promoPillData, className } = props;
  if (!promoPillData || promoPillData.length === 0) {
    return null;
  }

  const overridePillStyle = (color?: string, backgroundColor?: string) =>
    backgroundColor && color
      ? {
          color,
          backgroundColor,
        }
      : undefined;

  return (
    <div className={clsx(styles.promoContainer, className)}>
      {promoPillData.map((c) => (
        <div key={c.key} className={styles.promo} style={overridePillStyle(c.color, c.backgroundColor)}>
          {c.icon && <Image alt="" src={c.icon} width={22} height={22} />}
          <span>{c.copy}</span>
        </div>
      ))}
    </div>
  );
};
