import { ProductListDataRow } from 'src/types/CataloguePage.types';
import { pushCarmaEvent } from './carmaCritical';

export const pushCarmaRecommendationEvent = (
  recommendationType: 'similar' | 'recommended',
  results?: ProductListDataRow[],
  recommendationId?: string | null,
) => {
  if (results && recommendationId) {
    const skus = results.map((result) => result.sku);

    return (sku: string) => {
      pushCarmaEvent({
        event: 'recommendation_click',
        metadata: {
          type: recommendationType,
          recommendation_id: recommendationId,
          sku,
          displayed_skus: skus,
        },
      });
    };
  }

  return () => {};
};
