import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconHeart: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="heart">
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="m12.005 6-.131-.123C10.952 5.012 9.874 4 8 4c-3.928 0-6.819 5-3.653 9s6.656 6.5 7.658 7c.992-.5 4.482-3 7.648-7 3.166-4 .275-9-3.653-9-1.9 0-2.989 1.04-3.902 1.912L12.005 6Zm-.002 12.606c1.288-.845 4.098-3.053 6.709-6.35C21.286 9.002 18.875 5.2 16 5.2c-1.415 0-2.19.738-3.14 1.643l-.847.807-.827-.773a92.45 92.45 0 0 0-.076-.07C10.154 5.911 9.393 5.2 8 5.2c-2.876 0-5.286 3.803-2.712 7.055 2.613 3.302 5.425 5.509 6.715 6.351Z"
    />
  </Icon>
);
