import { isNil } from 'lodash';
import { getUserAuthDataFromCookies } from 'src/stores/authStore';
import { ProductListDataRow } from 'src/types/CataloguePage.types';
import { pushToDataLayer } from './pushToDataLayer';

export const enum DataLayerWatchlistEvent {
  watchlist_add_attempt = 'watchlist_add_attempt',
  watchlist_successful_add = 'watchlist_successful_add',
  watchlist_remove = 'watchlist_remove',
  watchlist_undos = 'watchlist_undos',
}

export type DataLayerWatchlistPayload = {
  isLoggedIn: boolean;
  user: {
    email?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    marketing?: boolean;
  } | null;
  sku: string | null;
  product: Partial<ProductListDataRow>;
  triggerEvent: 'logged_in' | 'successful_login' | 'successful_account_creation' | null;
};

type Source = 'HOME' | 'PLP' | 'PDP' | 'WATCHLIST' | undefined;

export const generateSourceString = (pathname: string) => {
  if (pathname === '/') {
    return 'HOME';
  }
  if (pathname.includes('/used-cars')) {
    return 'PLP';
  }
  if (pathname.includes('/product-detail')) {
    return 'PDP';
  }
  if (pathname.includes('/watchlist')) {
    return 'WATCHLIST';
  }

  return undefined;
};

export const getDefaultSource = () => {
  const { pathname, searchParams } = new URL(window.location.href);
  let source = searchParams.get('source') as Source;
  if (!source) {
    source = generateSourceString(pathname);
  }
  return source;
};

export const pushToDataLayerWatchList = (event: DataLayerWatchlistEvent, data?: Partial<DataLayerWatchlistPayload>) => {
  const userAuthData = getUserAuthDataFromCookies();
  const width = window.innerWidth;

  const getSku = () => {
    const { searchParams } = new URL(window.location.href);
    return searchParams.get('sku') ?? data?.sku ?? null;
  };

  pushToDataLayer({
    event,
    value: {
      isLoggedIn: !isNil(userAuthData),
      user: userAuthData
        ? {
            id: userAuthData.id,
          }
        : null,
      sku: getSku(),
      ecommerce: {
        items: [
          {
            index: 0,
            item_id: data?.product?.sku,
            item_name: data?.product?.title,
            currency: 'AUD',
            item_brand: data?.product?.make,
            item_category: data?.product?.model,
            item_category2: data?.product?.body,
            item_category3: data?.product?.transmission,
            item_category4: data?.product?.ancapRating,
            item_category5: data?.product?.buildYear,
            item_variant: data?.product?.colour,
            price: data?.product?.price,
          },
        ],
      },
      triggerEvent: (data?.triggerEvent ?? !isNil(userAuthData)) ? 'logged_in' : null,
      device: width < 550 ? 'mobile' : 'desktop',
      source: getDefaultSource(),
      date: new Date().toISOString(),
      ...data,
    },
  });
};
